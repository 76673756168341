<template>
  <div class="page">
    <page-content :show-search="true">
      <template #search>
        <table-search
          ref="search"
          :fields="search_fields"
          :default="search_default"
          @runSearch="runSearch"
          @changeField="searchChangeField"
        />
      </template>

      <template #default>
        <table-header
          ref="header"
          :title="title"
          :button-actions="header_actions"
          @runSearch="runSearch"
          :search-tips="$t('member.search_tips')"
        />

        <table-content
          :enableSelect="true"
          @selectedRowsChange="selectedRowsChange"
          :per-page="page_condition.pageSize"
          :per-page-dropdown-enabled="true"
          @perPageChange="perPageChange"
          :columns="columns"
          :rows="rows"
          :total-rows="total_rows"
          :current-page="current_page"
          :action-options="table_actions"
          @runTableAction="runTableAction"
          @sortChange="sortChange"
          @pageChange="pageChange"
          :permission="view_permission"
        />
      </template>

    </page-content>
  </div>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import PageContent from '@/components/PageContent'
import QrCode from 'qrcode'

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  name: 'kioskMemberQr',
  data() {
    return {
      title: common.getMenuName('kioskMemberQr'),
      view_permission: common.checkPermission('Kiosk_QrCode_List'),
      // view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage(),
      },
      status_map: [],
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: this.$t('member.chineseName'),
          field: 'chineseName',
          width: '180px',
          changeHTML: function (value, row) {
            let avatar = ''
            if (row.photo) {
              avatar = common.getServerUrl() + row.photo
            } else {
              avatar = require('@/assets/images/user.png')
            }
            return `
              <div class="d-flex align-items-center">
                <div class="b-avatar badge-secondary text-body rounded-circle default-photo-bg mr-05">
                  <span class="b-avatar-img">
                    <img src="${avatar}" alt="">
                  </span>
                </div>
                <div>${value}</div>
              </div> `
          },
        },
        {
          label: 'common.floor',
          field: 'floorName',
          width: '110px',
        },
        {
          label: 'common.room',
          field: 'roomName',
          width: '110px',
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          width: '110px',
        },
        {
          label: 'member.status',
          field: 'status',
          change: this.changeStatus,
          width: '100px',
        },
        // {
        //   label: 'kiosk.qr_code',
        //   field: 'qrCode',
        //   changeHTML: (val) => `<span class="badge badge-light-secondary wh-font-family-monospace mr-05 font-weight-normal">${val}</span>`
        // },
        {
          label: 'QRCode',
          field: 'qrBase64',
          changeHTML: (base64, row) => base64 ? `<img src="${base64}" alt="${row.qrCode}" width="30" height="30"/>` : '',
        },
        {
          label: '外部 QRCode(s)',
          field: 'externalQrBase64s',
          changeHTML: (base64s, row) => {
            const content = base64s.map(base64 => base64 ? `<img src="${base64}" alt="${row.qrCode}" width="30" height="30" class="mr-05" />` : '').join('')
            return `<div style="display:flex; gap: 0.5rem; flex-wrap: wrap;">${content}</div>`
          },
        },
        {
          label: 'common.action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          // permission: 'Kiosk_QrCode_Edit'
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: false,
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: false,
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: [],
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: [],
        },
        {
          field: 'statusList',
          label: 'member.status',
          type: 'select',
          multiple: true,
          options: [],
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: common.getCustomConfigByKey('floorID'),
        roomID: common.getCustomConfigByKey('roomID'),
      },
      selectedRows: [],
    }
  },

  computed: {
    header_actions() {
      return [
        {
          text: 'kiosk.preview',
          variant: 'primary',
          fun: this.preview,
          permission: 'Kiosk_QrCode_List',
          class: this.selectedRows.length === 0 ? 'disabled' : '',
        },
      ]
    },
  },

  mounted() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
    }
    common.getSearchRoomOptions('roomID', this.search_default.floorID, this)
    this.getList()

    common.getSearchMemberStatusOptions('statusList', this)
      .then(res => {
        this.status_map = res
      })
  },

  methods: {
    getList: async function () {
      if (!this.view_permission) return

      const api = '/memberqrcode/GetMemberQrCodeList'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)
      const { data } = await common.apiGetData(url)

      // Gen QRCodes to base64s
      const qrOptions = {
        margin: 0,
        width: 20,
      }
      for (const [i, item] of data.memberQrCodes.entries()) {
        const externalQrCodes = item.externalQrCodes?.split(',') ?? []
        const externalQrBase64s = []
        item.qrBase64 = item.qrCode ? await QrCode.toDataURL(item.qrCode, qrOptions) : ''
        for (const [j, externalQrCode] of externalQrCodes.entries()) {
          externalQrBase64s.push(externalQrCode ? await QrCode.toDataURL(externalQrCode, qrOptions) : '')
        }
        item.externalQrBase64s = externalQrBase64s
      }

      // Assign value to current component
      this.total_rows = data.total
      this.rows = data.memberQrCodes
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    editData: function (data) {
      this.$router.push({
        name: 'kioskMemberQr_edit',
        params: { id: common.encrypt(data.memberID) },
      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false,
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].value === value) {
          return this.status_map[i].text
        }
      }
    },
    selectedRowsChange({ selectedRows }) {
      this.selectedRows = selectedRows
    },
    preview() {
      if (this.selectedRows.length === 0) return

      const qrItems = this.selectedRows.map((row) => {
        return {
          chineseName: row.chineseName,
          englishName: row.englishName,
          // photo: row.photo ? common.getServerUrl() + row.photo : require('@/assets/images/user.png'),
          // qrBase64: row.qrBase64,
          qrCode: row.qrCode,
          memberCode: row.memberCode,
        }
      })
      console.log(qrItems)
      localStorage.setItem('kiosk_qrcode_preview_items', JSON.stringify(qrItems))

      window.open('/kiosk/memberQr/Preview', 'QrCodePreview')
    },
  },
}
</script>

<style lang="scss" scoped>
.page::v-deep {
  .vgt-wrap .vgt-table tbody tr:hover {
    th, td {
      background-color: #F1F5FD;
      cursor: pointer;
    }
  }
  .dark-layout .vgt-wrap .vgt-table tbody tr:hover {
    th, td {
      background-color: rgba(151, 120, 245, 0.15);
    }
  }
}
</style>
